import React, { useState } from "react";
import Seo from "../components/Seo";
import Audio from "../components/audioplayer/Audio";

import episode1 from "../assets/radio/Episode1.mp3";
import Layout from "../components/Layout";

import RadioPieceList from "../components/radioPieceList";
import RadioNavV2 from "../components/RadioNavV2";

const Station1 = () => {
  const [curTime, setCurTime] = useState(0);
  const [clickedTime, setClickedTime] = useState(0);
  const [clicked, setClicked] = useState(false);

  function setCurrentTime(time) {
    setCurTime(time);
  }

  return (
    <Layout>
      <div className="radioMain allTop">
        <RadioNavV2 highlighted={true} />

        <Seo
          title={`Kingston School of Art Foundation 2021 • Station 1 • Radio`}
        />

        <div className="radioPage">
          <div className="radioInner">
            {/* <h1 className="radioTitle">Title of station 1</h1> */}

            <Audio
              src={episode1}
              time={clickedTime}
              clicked={clicked}
              setCurTime={(time) => setCurrentTime(time)}
            />
            <RadioPieceList
              setClickedTime={(time) => setClickedTime(time)}
              setClicked={(clicked) => setClicked(clicked)}
              clicked={clicked}
              curTime={curTime}
              pieceList={[
                {
                  start: 66,
                  end: 863,
                  timestamp: "01:06",
                  title: "Understanding Creative Identity",
                  students: ["Olivia Entwistle"],
                },
                {
                  start: 888,
                  end: 1165,
                  timestamp: "14:48",
                  title: "Being an international student",
                  students: ["Rina Endo"],
                },
                {
                  start: 1170,
                  end: 1256,
                  timestamp: "19:30",
                  title: "Sounds of Home: Part Three",
                  students: ["Hannah Wong"],
                },
                {
                  start: 1279,
                  end: 1377,
                  timestamp: "21:19",
                  title: "Sounds of Home: Part Two",
                  students: ["Lucia Spelsberg-Hornsby"],
                },
                {
                  start: 1380,
                  end: 1531,
                  timestamp: "23:00",
                  title: "Lockdown with Parents",
                  students: ["Li-Chi Yeh"],
                },
                {
                  start: 1540,
                  end: 1585,
                  timestamp: "25:40",
                  title: "Advert",
                  students: ["Glump Collective"],
                },
                {
                  start: 1599,
                  end: 2211,
                  timestamp: "26:39",
                  title: "Rice Is For The Birds",
                  students: [
                    "Lola Komolafe",
                    "Lauren Christian",
                    "Luka Elliott",
                  ],
                },
                {
                  start: 2224,
                  end: 2336,
                  timestamp: "37:04",
                  title: "Mars: Part 1",
                  students: ["Aparna Kmitra", "Phoebe Russell", "Sophie Lin"],
                },
              ]}
            />
            <p className="radioCredits">
              Presenter: Joshua Cornwell / Sound Design and Editing: Alex Abrams
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Station1;
